import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Schedule, ScheduleInformation } from 'src/app/models/schedule.model';
import { SiteProject } from 'src/app/models/site-project.model';
import { Timesheet } from 'src/app/models/timesheet.model';
import { ISiteTimesheet } from '../../../helper';

export interface IInformation {
  user: string;
  text: string;
  type?: 'timesheet' | 'schedule';
  timesheet?: Timesheet;
  schedule?: Schedule;
  userGuid?: string;
}

@Component({
  selector: 'app-set-information-dialog',
  templateUrl: './set-information.dialog.html',
  styleUrls: ['./set-information.dialog.scss'],
  imports: [
    CommonModule, FormsModule,
    AppCommonModule,
    MainDialogComponent,
  ],
})
export class SetInformationComponent {

  formGroup: FormGroup;
  selectedItem = signal<IInformation>(null);

  sheet: ISiteTimesheet;
  schedule: Schedule;

  existingInformation: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    const projects: SiteProject[] = navParams.get('projects');
    this.sheet = navParams.get('sheet');
    let selectedTimesheet: Timesheet;
    let selectedSchedule: ScheduleInformation;
    if (this.sheet?.timesheets.length === 1) {
      selectedTimesheet = this.sheet.timesheets[0];
      this.existingInformation = selectedTimesheet.information != null;
    } else if (this.sheet?.timesheets.length > 1 && projects?.length > 0) {
      this.sheet.timesheets.map((ts) => {
        const project = projects.find((it) => it.guid === ts.project);
        ts.projectName = project?.name ?? '';
      });
    }
    let scheduleHasWorkers = false;
    this.schedule = navParams.get('schedule');
    if (this.schedule?.information?.length === 1) {
      selectedSchedule = this.schedule.information[0];
      this.existingInformation = selectedSchedule.text != null;
    }
    if (this.schedule?.information) {
      const workers = navParams.get('workers');
      this.schedule.information.map((info) => {
        const user = workers.find((u) => u.guid === info.user);
        if (user != null) {
          scheduleHasWorkers = true;
        }
        info.username = user?.displayName ?? null;
      });
    }
    if (selectedTimesheet && (!selectedSchedule || !scheduleHasWorkers)) {
      this.selectedItem.set({
        user: selectedTimesheet.username, text: selectedTimesheet.information,
        type: 'timesheet', timesheet: selectedTimesheet,
      });
    } else if (selectedSchedule && (this.sheet == null || this.sheet.timesheets?.length === 0)) {
      this.selectedItem.set({
        user: selectedSchedule.username, text: selectedSchedule.text,
        type: 'schedule', schedule: this.schedule, userGuid: selectedSchedule.user,
      });
    }

    this.formGroup = this.formBuilder.group({
      information: [this.selectedItem()?.text ?? '', Validators.required],
    });
  }

  selectTimesheet(ts: Timesheet) {
    this.selectedItem.set({
      user: ts.username, text: ts.information,
      type: 'timesheet', timesheet: ts,
    });
    this.existingInformation = ts.information != null;
    this.formGroup.get('information').setValue(ts.information ?? '');
  }

  selectSchedule(item: ScheduleInformation) {
    this.selectedItem.set({
      user: item.username, text: item.text,
      type: 'schedule', schedule: this.schedule,
      userGuid: item.user,
    });
    this.existingInformation = item.text != null;
    this.formGroup.get('information').setValue(item.text ?? '');
  }

  deleteInformation() {
    this.selectedItem.update((val) => {
      val.text = null;
      return val;
    });
    this.save(true);
  }

  save(remove?: boolean) {
    let { information } = this.formGroup.value;
    if (remove) {
      information = null;
    }
    if (this.selectedItem().type === 'timesheet') {
      this.selectedItem().timesheet.information = information;
    } else {
      const scheduleInformation = this.selectedItem().schedule.information.find((it) => it.user === this.selectedItem().userGuid);
      scheduleInformation.text = information;
    }
    this.dismiss(this.selectedItem());
  }

  dismiss(item?: IInformation) {
    this._modal.dismiss(item);
  }
}
