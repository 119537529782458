<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ selectedItem()?.user }}
  </div>
  @if (selectedItem()) {
    <div>
      <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
    </div>
  } @else {
    @for (ts of sheet.timesheets; track ts) {
      <ion-item button="true"
        (click)="selectTimesheet(ts)">
        {{ ts.username }} @if (ts.projectName) {
          <span>&nbsp;({{ ts.projectName }})</span>
        }: {{ ts.information }}
      </ion-item>
      }
      @for (item of schedule?.information; track item) {
        <ion-item button="true"
          (click)="selectSchedule(item)">
          {{ item.username }}: {{ item.text }}
        </ion-item>
      }
      }
      <ng-template #formTemplate>
        <form [formGroup]="formGroup"
          (ngSubmit)="save()">
          <ion-label position="stacked">{{ 'report.information' | translate }}</ion-label>
          <ion-item>
            <ion-input autocapitalize="on"
              formControlName="information"></ion-input>
            @if (existingInformation) {
              <ion-button slot="end"
                shape="round"
                color="danger"
                fill="clear"
                (click)="deleteInformation()">
                <ion-icon slot="icon-only"
                  name="trash-outline"></ion-icon>
              </ion-button>
            }
          </ion-item>
          <div class="ion-margin-top">
            <ion-button color="light"
              shape="round"
              type="submit"
              [disabled]="!formGroup.valid">{{ 'general.save' | translate }}</ion-button>
          </div>
        </form>
      </ng-template>
</app-main-dialog>